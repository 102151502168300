import React from "react";
import { Link } from "react-router-dom";

export default function OpportunitieCard(props) {
  return (
    <div className="opportunitiecard">
      <div className="content">
        <h3>
          <Link to={`/`}>{props.title}</Link>
        </h3>
        <div>{props.body}</div>
      </div>
    </div>
  );
}
