import React, { useState, useContext } from "react";
import MainLayout from "../components/MainLayout";

import swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";

export default function Login() {
  const { login } = useContext(AppContext);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [disabled, setDisabled] = useState(0);
  const navigate = useNavigate();

  const handleFormChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);

    axios
      .post("/users/signin", {
        email: form.email,
        password: form.password,
      })
      .then((response) => {
        if (!response.status === 200) {
          swal({
            title: "Duplication!",
            text: "Already registered!\nIf you are sure you didn't register before, then contact our page of facebook",
            icon: "warning",
          });
          setDisabled(false);
        } else {
          login(response.data.token);
          navigate("/");
        }
      })
      .catch((err) => {
        swal({
          title: "Error 😔",
          text: "Something wrong happend!",
          icon: "error",
        });
        setDisabled(false);
      });
  };
  return (
    <MainLayout navbar>
      <div className="login-main">
        <div className="container">
          <div className="login-card">
            <h2>- Sign in -</h2>
            <div className="login-body">
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="email"
                    onChange={handleFormChange}
                  />
                </div>
                <div className="input-group">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="********"
                    onChange={handleFormChange}
                  />
                </div>
                <button className="btn signinbtn" disabled={disabled}>
                  Next
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
