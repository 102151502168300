import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../AppContext";
import logo from "../../img/ooon.png";
import { useNavigate } from "react-router-dom";

import { FiMenu } from "react-icons/fi";
export default function Navbar(props) {
  const { state, logout, userdata } = useContext(AppContext);
  const navigate = useNavigate();
  console.log(props);
  const handleSignout = (e) => {
    logout();
  };
  const [showMenu, setShowMenu] = useState(false);
  const handleShowMenu = (e) => {
    setShowMenu(!showMenu);
  };
  return (
    <nav
      className={`navbar ${
        props.navProps?.transparent ? "transparentnav" : "whitenav"
      }`}
    >
      <div className="nav-logo">
        <Link to="/">
          <img src={logo} alt="ooon logo" />
        </Link>
      </div>
      <div className="nav-menu" onClick={handleShowMenu}>
        {showMenu ? "X" : <FiMenu />}
      </div>
      <div className={showMenu ? "open-nav" : "nav-content"}>
        <div className="navelement">
          <Link to="/opportunitie">Opportunitie</Link>
        </div>
        {/*<div className="navelement">
          <Link to="/postJob">Post a Job</Link>
  </div>*/}
        <div className="navelement">
          <Link to="/community">Community</Link>
        </div>
        <div className="navelement">
          <Link to="/education">Education</Link>
        </div>
        {/*<div className="navelement">
          <Link to="/about">About Us</Link>
</div>*/}
        <div className="nav-menu-opened" onClick={handleShowMenu}>
          {showMenu ? "X" : <FiMenu />}
        </div>
        <div className={showMenu ? "" : "nav-btns"}>
          {!state.isLoggedIn && (
            <>
              <Link to="/signin">
                <button className="btn signinbtn">Sign in</button>
              </Link>

              <Link to="/signup">
                <button className="btn signupbtn">Sign Up</button>
              </Link>
            </>
          )}
          {state.isLoggedIn && (
            <>
              {state.userdata?.role == "Admin" && (
                <button className="btn">
                  <Link to="/admin/dashboard">Dashboard</Link>
                </button>
              )}
              <button className="btn signoutbtn" onClick={handleSignout}>
                Sign out
              </button>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}
