import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import MainLayout from "../components/MainLayout";
import { Link } from "react-router-dom";

import chipsy from "../img/chipsy.webp";
import pepsi from "../img/pepsi.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-fade";
import { EffectFade, Autoplay } from "swiper";
import { FaRegArrowAltCircleDown } from "react-icons/fa";

import { useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

export default function Home() {
  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    className: "slider",
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          autoplaySpeed: 1000,
        },
      },
    ],
  };
  const goDownRef = useRef(null);
  const executeGoDownScroll = () =>
    goDownRef.current.scrollIntoView({ behavior: "smooth", block: "start" });

  const [refernce, inView] = useInView({
    threshold: 0.35,
  });
  const [transparent, setTransparent] = useState(true);
  const animation = useAnimation();
  useEffect(() => {
    if (!inView) {
      setTransparent(0);
    } else {
      setTransparent(1);
    }
  }, [inView, animation]);

  return (
    <MainLayout navbar navProps={{ transparent: transparent }} footer>
      <div className="section1">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          effect={"fade"}
          modules={[EffectFade, Autoplay]}
          loop={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
        >
          <SwiperSlide>
            <div className="section section-1-1">
              <h2>Need to find new opportunities?</h2>
              <p>Use our engine for your next job</p>
              <div className="btns-container">
                <Link to="/signin">
                  <button className="btn signinbtn">Sign in</button>
                </Link>

                <Link to="/signup">
                  <button className="btn signupbtn">Sign Up</button>
                </Link>
              </div>
              <div className="down" onClick={executeGoDownScroll}>
                <FaRegArrowAltCircleDown />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="section section-1-2">
              <h2>Search over 1000+ offer</h2>
              <p>Lorem</p>
              <div className="btns-container">
                <Link to="/signin">
                  <button className="btn signinbtn">Sign in</button>
                </Link>

                <Link to="/signup">
                  <button className="btn signupbtn">Sign Up</button>
                </Link>
              </div>
              <div
                className="down"
                onClick={executeGoDownScroll}
                ref={refernce}
              >
                <FaRegArrowAltCircleDown />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <div className="container">
        <section className="section2" ref={goDownRef}>
          <div className="part1">
            <div className="textcontainer">
              <h3 className="section2title">We Take</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting
              </p>
            </div>
          </div>
          <div className="part2">
            <div className="textcontainer">
              <h3 className="section2title">We Sort</h3>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book. It has
                survived not only five centuries, but also the leap into
                electronic typesetting
              </p>
            </div>
          </div>
        </section>
      </div>
      <section className="section3">
        <h2>Share your Opportunities for free</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
          nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat
          volutpat.
        </p>
        <button className="trnsbtn">let’s upload</button>
      </section>
      <div className="container">
        <section className="section4">
          <h3>Our Partners</h3>
          <Slider {...sliderSettings}>
            <div>
              <img src={chipsy} />
            </div>
            <div>
              <img src={pepsi} />
            </div>
            <div>
              <img src={chipsy} />
            </div>
            <div>
              <img src={pepsi} />
            </div>
            <div>
              <img src={chipsy} />
            </div>
            <div>
              <img src={pepsi} />
            </div>
          </Slider>
        </section>
      </div>
    </MainLayout>
  );
}
/*
   
      */
