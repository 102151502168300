import React, { useState, useEffect, useContext } from "react";
import swal from "sweetalert2";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../AppContext";

import MainLayout from "../components/MainLayout";
import blob from "../img/blob.png";
export default function SignUp() {
  const { login } = useContext(AppContext);

  const [form, setForm] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });
  const [formContinue, setFormContinue] = useState({
    location: "",
    isStudent: "",
    recentJob: "",
    companyName: "",
    university: "",
    degree: "",
    specialization: "",
    startYear: "",
    endYear: "",
  });
  const [userID, setUserID] = useState();
  const [cities, setCities] = useState([]);
  useEffect(function () {
    async function fetchCities() {
      try {
        const citiesretrued = await axios.get("/util/cities");
        setCities(citiesretrued.data.cities);
      } catch (e) {
        console.log(e);
      }
    }
    fetchCities();
  }, []);

  const [disabled, setDisabled] = useState(0);
  const [continueSigning, setContinueSigning] = useState(0);
  const startYears = [2021, 2020, 2019, 2018, 2017, 2016];
  const endYears = [2022, 2023, 2024, 2025, 2026];
  const handleFormChange = (e) => {
    console.log(e);
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleContinueFormChange = (e) => {
    console.log(formContinue);
    setFormContinue({ ...formContinue, [e.target.name]: e.target.value });
  };

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);

    axios
      .post("/users/signup", {
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email,
        password: form.password,
      })
      .then((response) => {
        //navigate("/");
        console.log(response);
        setUserID(response.data.user.id);
        setContinueSigning(1);
      })
      .catch((err) => {
        console.log(err);
      });
    setDisabled(false);
  };
  const handleNextSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);

    axios
      .patch("/users/signup", {
        id: userID,
        location: formContinue.location,
        isStudent: formContinue.isStudent,
        recentJob: formContinue.recentJob,
        companyName: formContinue.companyName,
        university: formContinue.university,
        degree: formContinue.degree,
        specialization: formContinue.specialization,
        startYear: formContinue.startYear,
        endYear: formContinue.endYear,
      })
      .then((response) => {
        login(response.data.token);
        navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
    setDisabled(false);
  };
  return (
    <MainLayout navbar footer>
      <div className="signup-container">
        {!continueSigning && (
          <div className="section-1">
            <h2 className="section-1-title">Create a FREE acount today !!</h2>
            <div className="container section-1-content">
              <form className="section-1-form" onSubmit={handleSubmit}>
                <div className="input-group">
                  <legend>First Name</legend>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    placeholder="Your First Name"
                    required
                    onChange={handleFormChange}
                  />
                </div>
                <div className="input-group">
                  <legend>Last Name</legend>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    placeholder="Your Last Name"
                    required
                    onChange={handleFormChange}
                  />
                </div>
                <div className="input-group">
                  <legend>Email</legend>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="email@something.com"
                    required
                    onChange={handleFormChange}
                  />
                </div>
                <div className="input-group">
                  <legend>Paswword</legend>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Your Password"
                    required
                    onChange={handleFormChange}
                  />
                </div>
                <button className="btn signinbtn" disabled={disabled}>
                  Register Now
                </button>
              </form>
              <div className="section-1-img">
                <img src={blob} />
              </div>
            </div>
          </div>
        )}
        {continueSigning===true && (
          <div className="section-2">
            <h2 className="section-2-title">Welcome abroad</h2>
            <p className="section-2-subtitle">Tell us more about you</p>
            <form className="section-1-form" onSubmit={handleNextSubmit}>
              <div className="input-group">
                <legend>Where are you located</legend>
                <select
                  name="location"
                  id="location"
                  value={formContinue.location}
                  required
                  onChange={handleContinueFormChange}
                >
                  <option value="" disabled hidden>
                    Select your city
                  </option>
                  {cities.map((option) => {
                    return <option value={option}>{option}</option>;
                  })}
                </select>
              </div>
              <div className="input-group">
                <legend>Are you a student?</legend>
                <label>
                  No, I am not
                  <input
                    type="radio"
                    name="isStudent"
                    value={1}
                    onChange={handleContinueFormChange}
                  />
                </label>
                <label>
                  Yes, I am
                  <input
                    type="radio"
                    name="isStudent"
                    value={0}
                    onChange={handleContinueFormChange}
                  />
                </label>
              </div>
              {formContinue.isStudent === "1" && (
                <>
                  <div className="input-group">
                    <legend>What was your most recent job title?</legend>
                    <input
                      type="text"
                      name="recentJob"
                      id="recentJob"
                      placeholder="Type here"
                      required
                      onChange={handleContinueFormChange}
                    />
                  </div>

                  <div className="input-group">
                    <legend>Most recent company name?</legend>
                    <input
                      type="text"
                      name="companyName"
                      id="companyName"
                      placeholder="Type here"
                      required
                      onChange={handleContinueFormChange}
                    />
                  </div>
                </>
              )}
              {formContinue.isStudent === "0" && (
                <>
                  <div className="input-group">
                    <legend>Your current Uni or school</legend>
                    <input
                      type="text"
                      name="university"
                      id="university"
                      placeholder="Type here"
                      required
                      onChange={handleContinueFormChange}
                    />
                  </div>
                  <div className="input-group">
                    <legend>Your current degree</legend>
                    <input
                      type="text"
                      name="degree"
                      id="degree"
                      placeholder="Type here"
                      required
                      onChange={handleContinueFormChange}
                    />
                  </div>
                  <div className="input-group">
                    <legend>Your specialization</legend>
                    <input
                      type="text"
                      name="specialization"
                      id="specialization"
                      placeholder="Type here"
                      required
                      onChange={handleContinueFormChange}
                    />
                  </div>
                  <div className="input-group">
                    <legend>The year you started your specialization</legend>
                    <select
                      name="startYear"
                      id="startYear"
                      value={formContinue.startYear}
                      required
                      onChange={handleContinueFormChange}
                    >
                      <option value="" disabled hidden>
                        Select the year
                      </option>
                      {startYears.map((option) => {
                        return <option value={option}>{option}</option>;
                      })}
                    </select>
                  </div>
                  <div className="input-group">
                    <legend>The expected end year of your eduction</legend>
                    <select
                      name="endYear"
                      id="endYear"
                      value={formContinue.endYear}
                      required
                      onChange={handleContinueFormChange}
                    >
                      <option value="" disabled hidden>
                        Select the year
                      </option>
                      {endYears.map((option) => {
                        return <option value={option}>{option}</option>;
                      })}
                    </select>
                  </div>
                </>
              )}
              <button className="btn signinbtn" disabled={disabled}>
                Continue
              </button>
            </form>
          </div>
        )}
      </div>
    </MainLayout>
  );
}
